import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, Teleport as _Teleport } from "vue"

const _hoisted_1 = ["aria-disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["id", "aria-expanded", "aria-disabled"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = ["innerHTML"]

import { onUnmounted, ref, computed, Teleport, onMounted } from "vue";
import { boxBool, trueFalseString } from "@utility/boolUtils";
import { genComponentId } from "@components/componentHelpers";
import IButtonCaretOption from "./interfaces/IButtonCaretOption";
import Icon from "@compUtility/icon.vue";
import {
  hideFloatedComponent,
  mountFloatedPosition,
  showFloatedComponent,
  unmountFloatedPosition,
} from "@compUtility/composables/floatedPosition";

interface Props {
  btnClass?: string | string[];
  icon?: string;
  text?: string;
  isLoading?: trueFalseString;
  isDisabled?: trueFalseString;
  caretClickOnly?: trueFalseString;
  caretOptions?: IButtonCaretOption[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'button',
  props: {
    btnClass: { default: "" },
    icon: { default: "" },
    text: { default: "" },
    isLoading: { type: [Boolean, String], default: false },
    isDisabled: { type: [Boolean, String], default: false },
    caretClickOnly: { type: [Boolean, String], default: false },
    caretOptions: { default: undefined }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

const props = __props;

const caretClickOnly = ref<boolean>(boxBool(props.caretClickOnly));
const caretOptionsOpen = ref(false);
const caretBtnId = ref(genComponentId("btn-crt"));
const calcPositionObj = ref<IDomPosition | null>(null);

const btnClassComputed = computed<string>(() => {
  const rtn: string[] = [];
  if (props.btnClass !== undefined && Array.isArray(props.btnClass)) {
    props.btnClass.forEach((x) => rtn.push(x));
  } else if (
    props.btnClass !== undefined &&
    typeof props.btnClass === "string" &&
    props.btnClass.trim() !== ""
  ) {
    rtn.push(props.btnClass);
  } else {
    rtn.push("btn-primary");
  }

  if (boxBool(props.isLoading) || boxBool(props.isDisabled)) {
    rtn.push("disabled");
  }

  if (rtn.indexOf("btn") <= -1) {
    rtn.unshift("btn");
  }

  return rtn.join(" ");
});

const caretMenuShown = computed<boolean>(() => caretOptionsOpen.value && calcPositionObj.value !== null);

const docListener = (e: Event) => {
  if (caretOptionsOpen.value) {
    caretOptionsOpen.value = false;
    document.removeEventListener("click", docListener);
  }
};

const onCaretClick = () => {
  var orgOpenVal = caretOptionsOpen.value;
  caretOptionsOpen.value = !caretOptionsOpen.value;
  !orgOpenVal ? showFloatedComponent(caretBtnId.value) : hideFloatedComponent(caretBtnId.value);
  return false;
};

const onClickEvent = () => {
  if (!boxBool(props.isLoading) && !boxBool(props.isDisabled)) {
    emit("click");
  }
};

const onActionClicked = (option: IButtonCaretOption) => {
  if (typeof option.action !== "function") {
    return;
  }

  option.action();
  emit("click", option);
};

onMounted(() => {
  if (boxBool(props.caretClickOnly) || (Array.isArray(props.caretOptions) && props.caretOptions.length > 0)) {
    mountFloatedPosition({
      componentId: caretBtnId.value,
      viewportId: caretBtnId.value + "-menu",
      position: { parent: "SE", child: "NE" },
      showTrigger: "manual",
      onPositionChange: (position) => {
        calcPositionObj.value = position;
      },
      onShowToggle: (show) => {
        caretOptionsOpen.value = show;
      },
    });
  }
});

onUnmounted(() => {
  unmountFloatedPosition(caretBtnId.value);
});

return (_ctx: any,_cache: any) => {
  return (Array.isArray(props.caretOptions) && props.caretOptions.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
      'btn-group': true,
      flex: true,
      open: caretOptionsOpen.value,
    })
      }, [
        (!caretClickOnly.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: _normalizeClass(btnClassComputed.value),
              "aria-disabled": _unref(boxBool)(props.isDisabled),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (onClickEvent()))
            }, [
              (props.icon && !_ctx.isLoading)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 0,
                    icon: props.icon ?? '',
                    class: _normalizeClass({ pr10: (props.text ?? '').length > 0 })
                  }, null, 8, ["icon", "class"]))
                : _createCommentVNode("", true),
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(Icon, {
                    key: 1,
                    icon: "fa-solid fa-circle-notch",
                    animate: true,
                    class: _normalizeClass({ pr10: (props.text ?? '').length > 0 })
                  }, null, 8, ["class"]))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("span", {
                  innerHTML: props.text ?? ''
                }, null, 8, _hoisted_2)
              ])
            ], 10, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([btnClassComputed.value, "btn dropdown-toggle"]),
          id: caretBtnId.value,
          "aria-haspopup": "true",
          "aria-expanded": caretOptionsOpen.value,
          "aria-disabled": _unref(boxBool)(props.isDisabled),
          onClick: _withModifiers(onCaretClick, ["prevent"])
        }, [
          (caretClickOnly.value && props.icon && !_ctx.isLoading)
            ? (_openBlock(), _createBlock(Icon, {
                key: 0,
                icon: props.icon ?? '',
                class: "pr10"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (caretClickOnly.value && _ctx.isLoading)
            ? (_openBlock(), _createBlock(Icon, {
                key: 1,
                icon: "fa-solid fa-circle-notch",
                animate: true,
                class: "pr10"
              }))
            : _createCommentVNode("", true),
          (caretClickOnly.value)
            ? _renderSlot(_ctx.$slots, "default", { key: 2 }, () => [
                _createElementVNode("span", {
                  innerHTML: props.text ?? ''
                }, null, 8, _hoisted_4)
              ])
            : _createCommentVNode("", true),
          _cache[2] || (_cache[2] = _createTextVNode("  ")),
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "caret" }, null, -1)),
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "sr-only" }, "Toggle Dropdown", -1))
        ], 10, _hoisted_3),
        (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
          _createElementVNode("ul", {
            class: _normalizeClass(['caret-dropdown-menu', !caretMenuShown.value ? 'hidden' : undefined]),
            style: _normalizeStyle(calcPositionObj.value ?? {}),
            id: `${caretBtnId.value}-menu`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.caretOptions, (option, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: _normalizeClass({ divider: option.isDivider }),
                id: option.id
              }, [
                (typeof option.action === 'function')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "#",
                      onClick: _withModifiers(($event: any) => (onActionClicked(option)), ["prevent"])
                    }, [
                      (option.icon)
                        ? (_openBlock(), _createBlock(Icon, {
                            key: 0,
                            icon: option.icon,
                            class: _normalizeClass({ mr5: option.text })
                          }, null, 8, ["icon", "class"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        innerHTML: option.text
                      }, null, 8, _hoisted_8)
                    ], 8, _hoisted_7))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                      (option.icon)
                        ? (_openBlock(), _createBlock(Icon, {
                            key: 0,
                            icon: option.icon,
                            class: _normalizeClass({ mr5: option.text })
                          }, null, 8, ["icon", "class"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        innerHTML: option.text
                      }, null, 8, _hoisted_10)
                    ]))
              ], 10, _hoisted_6))
            }), 128))
          ], 14, _hoisted_5)
        ]))
      ], 2))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        type: "button",
        class: _normalizeClass(btnClassComputed.value),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (onClickEvent()))
      }, [
        (props.icon && !_ctx.isLoading)
          ? (_openBlock(), _createBlock(Icon, {
              key: 0,
              icon: props.icon ?? '',
              class: _normalizeClass({ pr10: (props.text ?? '').length > 0 })
            }, null, 8, ["icon", "class"]))
          : _createCommentVNode("", true),
        (_ctx.isLoading)
          ? (_openBlock(), _createBlock(Icon, {
              key: 1,
              icon: "fa-solid fa-circle-notch",
              animate: true,
              class: _normalizeClass({ pr10: (props.text ?? '').length > 0 })
            }, null, 8, ["class"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createElementVNode("span", {
            innerHTML: props.text ?? ''
          }, null, 8, _hoisted_11)
        ])
      ], 2))
}
}

})