import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sr-only"
}

import { computed } from "vue";
import { trueFalseString } from "@utility/boolUtils";
import { useAppStore } from "@stores/appStore";

interface Props {
  icon: string | null;
  animate?: "none" | "spin" | "pulse" | trueFalseString | null | undefined;
  iconWrapperClass?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'icon',
  props: {
    icon: { default: "" },
    animate: { type: [String, Boolean, null], default: "none" },
    iconWrapperClass: { default: "" }
  },
  setup(__props: any) {

const props = __props;

const isCustomerWeb = useAppStore().isCustomerWeb();

const animateClass = computed<string>(() => {
  if (
    (typeof props.animate === "string" && ["true", "pulse"].indexOf(props.animate) >= 0) ||
    (typeof props.animate === "boolean" && props.animate)
  ) {
    return "fa-spin-pulse";
  } else if (typeof props.animate === "string" && props.animate === "spin") {
    return "fa-spin";
  }
  return "";
});

const baseIconClass = computed<string>(() => `${props.icon?.startsWith("fa-") ? "fa" : ""}`);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(props.iconWrapperClass)
  }, [
    _createElementVNode("i", {
      class: _normalizeClass([baseIconClass.value, props.icon, animateClass.value, 's1-icon', !_unref(isCustomerWeb) ? 'is-s1' : ''])
    }, null, 2),
    (animateClass.value !== '')
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Loading..."))
      : _createCommentVNode("", true)
  ], 2))
}
}

})