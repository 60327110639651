import { getCustomerPortalInteriorPagesPaths } from "@du/routes/cw-routes";

//prettier-ignore
const BRAND_CSS_BUNDLE_MAP = new Map<string, Function>([
  ["cw.css",            () => import("../../css/legacy/custPortal/app/_app-config.export")],
  ["capcity.css",       () => import("../../css/legacy/custPortal/app/capcity/_app-config.export")],
  ["sauder.css",        () => import("../../css/legacy/custPortal/app/sauder/_app-config.export")],
  ["keystone-base.css", () => import("../../css/legacy/custPortal/app/keystone/_app-config.export")],
  ["mauger.css",        () => import("../../css/legacy/custPortal/app/mauger/_app-config.export")],
  ["haly.css",          () => import("../../css/legacy/custPortal/app/haly/_app-config.export")],
  ["emery.css",         () => import("../../css/legacy/custPortal/app/emery/_app-config.export")],
  ["affordable.css",    () => import("../../css/legacy/custPortal/app/affordable/_app-config.export")],
]);

//#region js imports

export const importS1WebLegacyJsBundles = (libFunction: string): Promise<any> => {
  return import("jquery").then((x) => {
    if (libFunction === "hana.pages.tickerDisplay.init") {
      const epcImports = [
        import("../legacy/s1Web/hana/lib/hana.prototype.js"),
        import("../legacy/s1Web/hana/hana.js"),
        import("../legacy/s1Web/hana/lib/hana.constants.js"),
        import("../legacy/s1Web/hana/lib/hana.appEnum.js"),
        import("../legacy/s1Web/hana/lib/hana.utility.js"),
        import("../legacy/s1Web/hana/lib/hana.webservices.js"),
        import("../legacy/s1Web/hana/lib/hana.templating.js"),
        import("../legacy/s1Web/hana/lib/hana.uiCommon.js"),
        import("../legacy/s1Web/hana/lib/hana.loadingIndicator.js"),
        import("../legacy/s1Web/hana/lib/hana.map.js"),
        import("../legacy/s1Web/hana/hana.pages.epcDisplay.js"),
        import("../legacy/s1Web/hana/hana.pages.epcGraphSet.js"),
        import("../legacy/s1Web/hana/hana.pages.tickerDisplay.js"),
      ];
      return Promise.all(epcImports);
    }

    const s1WebImports = [
      import("../legacy/s1Web/lib/morris/morris.js"),
      import("../legacy/s1Web/lib/video/responsible-video.js"),

      import("../legacy/s1Web/hana/lib/hana.prototype.js"),
      import("../legacy/s1Web/hana/hana.js"),
      //#region Hana Util/Lib
      import("../legacy/s1Web/hana/lib/hana.spin.js"),
      import("../legacy/s1Web/hana/lib/hana.constants.js"),
      import("../legacy/s1Web/hana/lib/hana.appEnum.js"),
      import("../legacy/s1Web/hana/lib/hana.utility.js"),
      import("../legacy/s1Web/hana/lib/hana.webservices.js"),
      import("../legacy/s1Web/hana/lib/hana.templating.js"),
      import("../legacy/s1Web/hana/lib/hana.alert.js"),
      import("../legacy/s1Web/hana/lib/hana.uiCommon.js"),
      import("../legacy/s1Web/hana/lib/hana.modal.js"),
      import("../legacy/s1Web/hana/lib/hana.loadingIndicator.js"),
      import("../legacy/s1Web/hana/lib/hana.map.js"),
      import("../legacy/s1Web/hana/lib/hana.s1CustomerCommon.js"),
      import("../legacy/s1Web/hana/lib/hana.dtTools.js"),
      //#endregion Hana Util/lib

      //#region formbuilder
      import("../legacy/s1Web/lib/formBuilder/form-builder.js"),
      import("../legacy/s1Web/lib/formBuilder/form-render.js"),
      import("../legacy/s1Web/hana/hana.pages.formListing.js"),
      import("../legacy/s1Web/hana/hana.pages.formBuilderEditor.js"),
      import("../legacy/s1Web/hana/lib/hana.formBuilder.js"),
      import("../legacy/s1Web/hana/lib/hana.eDocumentSigning.js"),
      //#endregion formbuilder

      //#region Pages
      import("../legacy/s1Web/hana/hana.siteMaster.js"),
      import("../legacy/s1Web/hana/hana.pages.dashboard.js"),
      import("../legacy/s1Web/hana/hana.pages.customerSearch.js"),
      import("../legacy/s1Web/hana/hana.pages.customerDetail.js"),
      import("../legacy/s1Web/hana/hana.pages.manageTickerSymbols.js"),
      import("../legacy/s1Web/hana/hana.pages.applicationLogging.js"),
      import("../legacy/s1Web/hana/hana.pages.resources.js"),
      import("../legacy/s1Web/hana/hana.pages.posts.js"),
      import("../legacy/s1Web/hana/hana.pages.posts-edit.js"),
      import("../legacy/s1Web/hana/hana.pages.calendar.js"),
      import("../legacy/s1Web/hana/hana.pages.directory.js"),
      import("../legacy/s1Web/hana/hana.pages.documentPrintQueue.js"),
      import("../legacy/s1Web/hana/hana.pages.userRightsManagement.js"),
      import("../legacy/s1Web/hana/hana.pages.manageDataSets.js"),
      import("../legacy/s1Web/hana/hana.pages.redirectManager.js"),
      import("../legacy/s1Web/hana/hana.pages.creditEnrollmentReview.js"),
      import("../legacy/s1Web/hana/hana.pages.creditPrintQueue.js"),
      import("../legacy/s1Web/hana/hana.pages.customerApplication.js"),
      import("../legacy/s1Web/hana/hana.pages.incidentManagement.js"),
      import("../legacy/s1Web/hana/hana.pages.invoiceAds.js"),
      import("../legacy/s1Web/hana/hana.pages.columbiaInvoices.js"),
      import("../legacy/s1Web/hana/hana.pages.parcelData.js"),
      import("../legacy/s1Web/hana/hana.pages.shipleyPricing.js"),
      import("../legacy/s1Web/hana/hana.pages.ticketListing.js"),
      import("../legacy/s1Web/hana/hana.pages.pcmPriceCurveAdmin.js"),
      import("../legacy/s1Web/hana/hana.pages.pcmQuoteModels.js"),
      import("../legacy/s1Web/hana/hana.pages.utilities.js"),
      import("../legacy/s1Web/hana/hana.pages.eclData.js"),
      import("../legacy/s1Web/hana/hana.pages.templateListing.js"),
      import("../legacy/s1Web/hana/hana.pages.graphGroupingAdmin.js"),
      import("../legacy/s1Web/hana/hana.pages.customerCorrespondence.js"),
      import("../legacy/s1Web/hana/hana.pages.tankMonitorConfig.js"),
      import("../legacy/s1Web/hana/hana.pages.logWatchConfig.js"),
      import("../legacy/s1Web/hana/hana.pages.webLoginManager.js"),
      import("../legacy/s1Web/hana/hana.pages.priceToCompare.js"),
      import("../legacy/s1Web/hana/hana.pages.creditCheckLite.js"),
      import("../legacy/s1Web/hana/hana.pages.callRecordingSearch.js"),
      import("../legacy/s1Web/hana/hana.pages.pjmReporting.js"),
      import("../legacy/s1Web/hana/hana.pages.commercialCreditApplicationListing.js"),
      import("../legacy/s1Web/hana/hana.pages.siteMaintenance.js"),
      import("../legacy/s1Web/hana/hana.pages.pageContentManager.js"),
      import("../legacy/s1Web/hana/hana.pages.rewardsAdmin.js"),
      import("../legacy/s1Web/hana/hana.pages.hostDenyListAdmin.js"),
      import("../legacy/s1Web/hana/hana.pages.companyAlert.js"),
      import("../legacy/s1Web/hana/hana.pages.campaignManager.js"),
      import("../legacy/s1Web/hana/hana.pages.workflowListing.js"),
      //#endregion Pages

      //#region Dashboard
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.genericDataSet.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.genericGraphGrouping.js"),
      import("../legacy/s1Web/hana/dashboard/STE/hana.dashboard.steActiveCustomers.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.zipPriceWidget.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.customerApplication.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.tickerWidget.js"),
      import("../legacy/s1Web/hana/dashboard/hana.dashboard.ticketListing.js"),
      //#endregion Dashboard
      //
      //
      //#region DashboardConfig
      import("../legacy/s1Web/hana/dashboard/config/dashboardConfig.js"),
      import("../legacy/s1Web/hana/dashboard/config/genericDashboardWidgetConfig.js"),
      import("../legacy/s1Web/hana/dashboard/config/customerApplicationConfig.js"),
      import("../legacy/s1Web/hana/dashboard/config/zipPriceCheckConfig.js"),
      import("../legacy/s1Web/hana/dashboard/config/tickerWidgetConfig.js"),
      import("../legacy/s1Web/hana/dashboard/config/ticketListingConfig.js"),
      //#endregion DashboardConfig
      //
      //
      //#region Widgets
      import("../legacy/s1Web/hana/hana.widget.assetUpload.js"),
      import("../legacy/s1Web/hana/hana.widget.categoryManager.js"),
      import("../legacy/s1Web/hana/hana.widget.campaign.js"),
      import("../legacy/s1Web/hana/hana.widget.versionedTemplate.js"),
      import("../legacy/s1Web/hana/hana.widget.priceToCompare.js"),
      import("../legacy/s1Web/hana/hana.widget.addressLookup.js"),
      import("../legacy/s1Web/hana/hana.widget.note.js"),
      import("../legacy/s1Web/hana/hana.widget.ftpProxy.js"),
      import("../legacy/s1Web/hana/hana.widget.ticket.js"),
      import("../legacy/s1Web/hana/hana.widget.priceToCompareEditPrices.js"),
      import("../legacy/s1Web/hana/hana.widget.priceToCompareGraph.js"),
      import("../legacy/s1Web/hana/hana.widget.customerDetailContacts.js"),
      import("../legacy/s1Web/hana/hana.widget.customerNotes.js"),
      import("../legacy/s1Web/hana/hana.widget.customerServiceLocations.js"),
      import("../legacy/s1Web/hana/hana.widget.customerTrackingCodes.js"),
      import("../legacy/s1Web/hana/hana.widget.customerUsageHistory.js"),
      import("../legacy/s1Web/hana/hana.widget.customerDocuments.js"),
      import("../legacy/s1Web/hana/hana.widget.payment.js"),
      import("../legacy/s1Web/hana/hana.widget.paymentProfile.js"),
      import("../legacy/s1Web/hana/hana.widget.valueGram.js"),
      import("../legacy/s1Web/hana/hana.widget.s1Opportunities.js"),
      import("../legacy/s1Web/hana/hana.widget.s1Account.js"),
      import("../legacy/s1Web/hana/hana.widget.leakForm.js"),
      import("../legacy/s1Web/hana/hana.widget.campaignEdit.js"),
    ];

    return Promise.all(s1WebImports);
  });
};

export const importFormBuilderJsBundles = () =>
  Promise.all([
    import("../legacy/s1Web/lib/formBuilder/form-builder.js"),
    import("../legacy/s1Web/lib/formBuilder/form-render.js"),
    import("../legacy/s1Web/hana/hana.pages.formListing.js"),
    import("../legacy/s1Web/hana/hana.pages.formBuilderEditor.js"),
    import("../legacy/s1Web/hana/lib/hana.formBuilder.js"),
    import("../legacy/s1Web/hana/lib/hana.eDocumentSigning.js"),
  ]);

export const importCustomerPortalJsBundles = () => {
  return import("jquery").then((x) => {
    const customerPortalJsImports = [
      import("../legacy/custPortal/cw/cw.js"),
      import("../legacy/custPortal/cw/lib/cw.constants.js"),
      import("../legacy/custPortal/cw/lib/cw.prototype.js"),
      import("../legacy/custPortal/cw/lib/cw.modal.js"),
      import("../legacy/custPortal/cw/lib/cw.webservices.js"),
      import("../legacy/custPortal/cw/lib/cw.templating.js"),
      import("../legacy/custPortal/cw/lib/cw.appEnum.js"),
      import("../legacy/custPortal/cw/lib/cw.utility.js"),
      import("../legacy/custPortal/cw/lib/cw.dtTools.js"),
      import("../legacy/custPortal/cw/lib/cw.alert.js"),
      import("../legacy/custPortal/cw/lib/cw.uiCommon.js"),
      import("../legacy/custPortal/cw/lib/cw.loadingIndicator.js"),
      import("../legacy/custPortal/cw/lib/cw.formBuilder.js"),
      import("../legacy/custPortal/cw/lib/cw.eDocumentSigning.js"),
      import("../legacy/custPortal/cw/cw.siteMaster.js"),
      import("../legacy/custPortal/cw/cw.pages.passwordReset.js"),
      import("../legacy/custPortal/cw/cw.pages.application.js"),
      import("../legacy/custPortal/cw/cw.pages.brokerdashboard.js"),
      import("../legacy/custPortal/cw/cw.pages.dashboard.js"),
      import("../legacy/custPortal/cw/cw.pages.invoiceHistory.js"),
      import("../legacy/custPortal/cw/cw.pages.pendingDeliveries.js"),
      import("../legacy/custPortal/cw/cw.pages.deliveryHistory.js"),
      import("../legacy/custPortal/cw/cw.pages.brokeraccountsummary.js"),
      import("../legacy/custPortal/cw/cw.pages.paymentHistory.js"),
      import("../legacy/custPortal/cw/cw.pages.rewards.js"),
      import("../legacy/custPortal/cw/cw.pages.brokerSiteAdmin.js"),
      import("../legacy/custPortal/cw/cw.pages.makePayment.js"),
      import("../legacy/custPortal/cw/cw.pages.documentHistory.js"),
      import("../legacy/custPortal/cw/cw.pages.renewal.js"),
      import("../legacy/custPortal/cw/cw.pages.commercialCreditApplicationCheck.js"),
      import("../legacy/custPortal/cw/cw.pages.budgetManager.js"),
      import("../legacy/custPortal/cw/cw.widget.contactUpdate.js"),
      import("../legacy/custPortal/cw/cw.widget.payment.js"),
      import("../legacy/custPortal/cw/cw.widget.deliveryRequest.js"),
      import("../legacy/custPortal/cw/cw.widget.budgetConfig.js"),
      import("../legacy/custPortal/cw/cw.pages.anonymousCorrespondence.js"),
    ];
    return Promise.all(customerPortalJsImports);
  });
};

//#endregion js imports

//#region css imports
export const importS1WebLegacyCssBundles = (): Promise<any> =>
  Promise.all([
    import("../../css/shared/font-awesome-6.7.1/_font-awesome.export"),
    import("../../css/legacy/s1web/bs/_bootstrap.export"),
    import("../../css/legacy/s1web/bs-theme-base/_base-config.export"),
    import("../../css/legacy/s1web/app/_summernote.export"),
    import("../../css/_app-config.export"),
    import("../../css/legacy/s1web/app/_app-config.export"),
  ]);

export const importCustomerPortalCssBundles = (): Promise<any> => {
  const pathName = window.location.pathname?.toLowerCase() ?? "";
  const isCustPortalInterior = getCustomerPortalInteriorPagesPaths().some((s) => pathName.startsWith(s));
  const bundles = [
    import("../../css/shared/font-awesome-6.7.1/_font-awesome.export"),
    import("../../css/legacy/custPortal/bootstrap_v3.3.5/_bootstrap.export"),
  ];
  if (isCustPortalInterior) {
    bundles.push(setBrandLegacyCssBundle("cw.css"));
    bundles.push(import("../../css/legacy/custPortal/legacy/_legacy_config.export"));
  }
  return Promise.all(bundles);
};

export const setBrandLegacyCssBundle = (brandBundle: string): Promise<any> => {
  const bundleImport = BRAND_CSS_BUNDLE_MAP.get(brandBundle);
  if (bundleImport && typeof bundleImport === "function") {
    return bundleImport();
  }

  console.warn(`No brand bundle found for ${brandBundle}`);
  return Promise.resolve();
};

//#endregion css imports
