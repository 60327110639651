import { createApp } from "vue/dist/vue.esm-bundler";
import { warn } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";

import { routes as s1Routes } from "./routes/s1-routes";
import { routes as cwRoutes } from "./routes/cw-routes";

import { SiteType } from "./appEnum/SiteType";
import { IAppArgs } from "./interfaces/IAppArgs";
import { useAppStore } from "./stores/appStore";
import { usePageStore } from "./stores/pageStore";
import { useContentStore } from "./stores/contentStore";
import CookieManager from "./interfaces/utility/CookieManager";
import { hasHideHubspotChatParam, isTestingSite } from "./utility/webServices";
import registerDirectives from "./startup/directives/registration";
import { importS1WebLegacyCssBundles } from "../legacy/LegacyBundleImports";

interface appPluginArgs {
  siteTypeRouting?: SiteType;
}

if (process.env.NODE_ENV !== "production") {
  window["VUE_DEVTOOLS_CONFIG"] = {
    openInEditorHost: `https://${window.location.hostname}:8999/`,
  };
}

const addCssBundles = (siteType: SiteType) => {
  if (siteType === SiteType.CustomerWeb) {
  } else if (siteType === SiteType.S1) {
    importS1WebLegacyCssBundles();
  }
};

const registerAppPlugins = (app: any, args: appPluginArgs) => {
  const pinia = createPinia();
  app.use(pinia);

  if (args.siteTypeRouting) {
    const router = createRouter({
      history: createWebHistory(),
      routes: args.siteTypeRouting === SiteType.CustomerWeb ? cwRoutes : s1Routes,
    });
    const appArgsStore = useAppStore();
    const pageStore = usePageStore();
    router.beforeEach(async (to, from) => {
      const pm = await pageStore.getPageMeta(to.path);
      if (pm !== null) {
        appArgsStore.setCurrentPageMeta(pm);
      }
    });

    app.use(router);
  }

  return app;
};

/**
 *
 * @param rootComponent
 * @param rootComponentArgs
 * @returns Vue app instance
 */
const createNonRoutedApp = (rootComponent: any, rootComponentArgs?: any): any => {
  if (!rootComponent) {
    console.warn(
      "==createNonRoutedApp()==> unknown root component argument",
      rootComponent,
      rootComponentArgs
    );
  }
  var args = (<any>window)?.hana?.appStartArgs || (<any>window)?.cw?.appStartArgs;
  includeBrandCssVars(args?.SiteTypeId, args?.BrandCssVars);
  addCssBundles(args?.SiteTypeId);
  const app = createApp(rootComponent, rootComponentArgs);
  registerAppPlugins(app, {});
  const appArgsStore = useAppStore();
  appArgsStore.appArgs = args;
  checkForContentEditor();

  registerDirectives(app);

  if (hasHideHubspotChatParam() && isTestingSite()) {
    hideHubspotChat();
  }

  return app;
};

const startApp = (appArgs: IAppArgs) => {
  includeBrandCssVars(appArgs?.SiteTypeId, appArgs?.BrandCssVars);
  addCssBundles(appArgs?.SiteTypeId);
  let app = createApp({});
  app = registerAppPlugins(app, {
    siteTypeRouting: appArgs.SiteTypeId,
  });

  const appArgsStore = useAppStore();
  appArgsStore.appArgs = appArgs;
  checkForContentEditor();

  registerDirectives(app);

  app.mount("#s1");
};

const includeBrandCssVars = (siteType?: SiteType, brandCssVars?: string): void => {
  if (
    siteType === undefined ||
    siteType === SiteType.S1 ||
    (siteType === SiteType.CustomerWeb && (brandCssVars ?? "") !== "")
  ) {
    var cssFile = siteType === undefined || siteType === SiteType.S1 ? "ShipleyOne" : brandCssVars;
    import(`@css/brands/${cssFile}.vue`)
      .then((x) => {
        const styleApp = createApp(x.default, {});
        const cssRootId = "s1-style-vars";
        let $el = document.getElementById(cssRootId);
        if (!$el) {
          $el = document.createElement("div");
          $el.id = cssRootId;
          document.body.appendChild($el);
        }

        if ($el.innerHTML === "") {
          styleApp.mount(`#${cssRootId}`);
        }
      })
      .catch((e) => {
        warn("==Could not import brand specific css var file==>", e);
      });
  }
};

const checkForContentEditor = () => {
  const cm = new CookieManager("s1ContEditTok");
  if (cm.IsCookieValid && (cm.Cookie ?? "").trim() !== "") {
    useContentStore().setAnonContentEditorToken(cm.Cookie);
  }
};

const hideHubspotChat = () => {
  setTimeout(() => {
    const iframe = document.getElementById("hubspot-conversations-iframe");
    if (iframe) {
      iframe.remove();
    }
  }, 500);
};

export { startApp, createNonRoutedApp };
