<template>
  <span :class="props.iconWrapperClass">
    <i :class="[baseIconClass, props.icon, animateClass, 's1-icon', !isCustomerWeb ? 'is-s1' : '']"></i>
    <span
      v-if="animateClass !== ''"
      class="sr-only"
      >Loading...</span
    >
  </span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { trueFalseString } from "@utility/boolUtils";
import { useAppStore } from "@stores/appStore";

interface Props {
  icon: string | null;
  animate?: "none" | "spin" | "pulse" | trueFalseString | null | undefined;
  iconWrapperClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  icon: "",
  animate: "none",
  iconWrapperClass: "",
});

const isCustomerWeb = useAppStore().isCustomerWeb();

const animateClass = computed<string>(() => {
  if (
    (typeof props.animate === "string" && ["true", "pulse"].indexOf(props.animate) >= 0) ||
    (typeof props.animate === "boolean" && props.animate)
  ) {
    return "fa-spin-pulse";
  } else if (typeof props.animate === "string" && props.animate === "spin") {
    return "fa-spin";
  }
  return "";
});

const baseIconClass = computed<string>(() => `${props.icon?.startsWith("fa-") ? "fa" : ""}`);
</script>
<style lang="less" scoped>
@font-face {
  font-family: icomoon;
  src: url("@css/fonts/icomoon/icomoon.eot?mw781m");
  src: url("@css/fonts/icomoon/icomoon.eot?mw781m#iefix") format("embedded-opentype"),
    url("@css/fonts/icomoon/icomoon.woff2?mw781m") format("woff2"),
    url("@css/fonts/icomoon/icomoon.woff?mw781m") format("woff"),
    url("@css/fonts/icomoon/icomoon.ttf?mw781m") format("truetype"),
    url("@css/fonts/icomoon/icomoon.svg?mw781m#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
  font-family: icomoon !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-graduate:before {
  content: "\e940";
}

.icon-slider-arrow-right:before {
  content: "\e900";
}

.icon-slider-arrow-left:before {
  content: "\e901";
}

.icon-arrow-down:before {
  content: "\e902";
}

.icon-building-fitout:before {
  content: "\e903";
}

.icon-emergency-services:before {
  content: "\e904";
}

.icon-asphalt:before {
  content: "\e905";
}

.icon-success-stories:before {
  content: "\e906";
}

.icon-biodiesel:before {
  content: "\e907";
}

.icon-off-road-diesel:before {
  content: "\e908";
}

.icon-kerosene:before {
  content: "\e909";
}

.icon-fleet-one:before {
  content: "\e90a";
}

.icon-heating-unit:before {
  content: "\e90b";
}

.icon-ductless-hvac:before {
  content: "\e90c";
}

.icon-payment-options:before {
  content: "\e90d";
}

.icon-low-income-assistance:before {
  content: "\e90e";
}

.icon-indoor-air:before {
  content: "\e90f";
}

.icon-water-heater:before {
  content: "\e910";
}

.icon-boiler:before {
  content: "\e911";
}

.icon-generator:before {
  content: "\e912";
}

.icon-fireplace:before {
  content: "\e913";
}

.icon-plumbing:before {
  content: "\e914";
}

.icon-duct-cleaning:before {
  content: "\e915";
}

.icon-air-conditioning:before {
  content: "\e916";
}

.icon-youtube:before {
  content: "\e917";
}

.icon-wholesale-fuels:before {
  content: "\e918";
}

.icon-twitter:before {
  content: "\e919";
}

.icon-transport-trucking:before {
  content: "\e91a";
}

.icon-service:before {
  content: "\e91b";
}

.icon-search:before {
  content: "\e91c";
}

.icon-savings:before {
  content: "\e91d";
}

.icon-reliability:before {
  content: "\e91e";
}

.icon-quote:before {
  content: "\e91f";
}

.icon-protection-plans:before {
  content: "\e920";
}

.is-s1.icon-propane:before {
  content: "\e921";
}

.icon-preventative-maintenance:before {
  content: "\e922";
}

.icon-play:before {
  content: "\e923";
}

.icon-pinterest:before {
  content: "\e924";
}

.icon-phone:before {
  content: "\e925";
}

.is-s1.icon-natural-gas:before {
  content: "\e926";
}

.icon-menu:before {
  content: "\e927";
}

.icon-login:before {
  content: "\e928";
}

.icon-linkedin:before {
  content: "\e929";
}

.is-s1.icon-installation:before {
  content: "\e92a";
}

.icon-hvac:before {
  content: "\e92b";
}

.icon-home:before {
  content: "\e92c";
}

.is-s1.icon-heating-oil:before {
  content: "\e92d";
}

.icon-heating-cooling:before {
  content: "\e92e";
}

.icon-heat-pump:before {
  content: "\e92f";
}

.icon-headquarters:before {
  content: "\e930";
}

.icon-greenscreen:before {
  content: "\e931";
}

.icon-google:before {
  content: "\e932";
}

.icon-fleet-fueling:before {
  content: "\e933";
}

.icon-facebook:before {
  content: "\e934";
}

.icon-equipment:before {
  content: "\e935";
}

.icon-energy:before {
  content: "\e936";
}

.icon-email:before {
  content: "\e937";
}

.is-s1.icon-electricity:before {
  content: "\e938";
}

.icon-consultant:before {
  content: "\e939";
}

.icon-benefits:before {
  content: "\e93a";
}

.icon-arrow:before {
  content: "\e93b";
}

.icon-arrow-2:before {
  content: "\e93c";
}

.is-s1.icon-address:before {
  content: "\e93d";
}

.icon-untitled:before {
  content: "\e93e";
}

.icon-cross:before {
  content: "\e93f";
}
</style>
